import React from "react";

import { Col, Image, Row } from "antd";
import {
  CustomizeTabs,
  ImageContainer,
  TopHeading,
  Wrapper,
} from "./index.style";
import {
  allImages,
  farmerImages,
  fieldImages,
  lifeImages,
  yearsImages,
} from "../../assets/data";

const Gallery = () => {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "All",
      children: (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
            {allImages.map((item, i) => (
              <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                <ImageContainer key={i}>
                  <Image src={item.image} alt="" />
                </ImageContainer>
              </Col>
            ))}
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "50 Years",
      children: (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
            {yearsImages.map((item, i) => (
              <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                <ImageContainer key={i}>
                  <Image src={item.image} alt="" />
                </ImageContainer>
              </Col>
            ))}
          </Row>
        </>
      ),
    },
    {
      key: "3",
      label: "Farmers",
      children: (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
            {farmerImages.map((item, i) => (
              <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                <ImageContainer key={i}>
                  <Image src={item.image} alt="" />
                </ImageContainer>
              </Col>
            ))}
          </Row>
        </>
      ),
    },
    // {
    //   key: "4",
    //   label: "Fields",
    //   children: (
    //     <>
    //       <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
    //         {fieldImages.map((item, i) => (
    //           <Col xs={{ span: 12 }} lg={{ span: 8 }}>
    //             <ImageContainer key={i}>
    //               <Image src={item.image} alt="" />
    //             </ImageContainer>
    //           </Col>
    //         ))}
    //       </Row>
    //     </>
    //   ),
    // },
    {
      key: "4",
      label: "Life at NSL",
      children: (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
            {lifeImages.map((item, i) => (
              <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                <ImageContainer key={i}>
                  <Image src={item.image} alt="" />
                </ImageContainer>
              </Col>
            ))}
          </Row>
        </>
      ),
    },
  ];
  return (
    <Wrapper>
      <TopHeading>
        <h1>GALLERY</h1>
      </TopHeading>
      <CustomizeTabs
        defaultActiveKey="1"
        centered
        items={items}
        onChange={onChange}
      />
    </Wrapper>
  );
};

export default Gallery;
