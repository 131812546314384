import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../screens/Home";
import MainLayout from "../components/layout";
import Seeding from "../screens/Seeding";
import Gallery from "../screens/gallery";
import News from "../screens/news";
import Contact from "../screens/contact";
// Quiz Layout
import QuizLayout from "../components/layout/QuizLayout";
import Celebrate from "../screens/quizScreen/Celebrate";
import ThankYou from "../screens/quizScreen/thankyou";
import Result from "../screens/quizScreen/result";
import Instructions from "../screens/quizScreen/instructions";
import SignUpForm from "../screens/quizScreen/SIgninForm";
import Question from "../screens/quizScreen/quizs";

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/seeding" element={<Seeding />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
        <Route element={<QuizLayout />}>
          <Route path="/celebrate" element={<Celebrate />} />
          <Route path="/sign_up_form" element={<SignUpForm />} />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/quiz" element={<Question />} />
          <Route path="/result" element={<Result />} />
          <Route path="/thankyou" element={<ThankYou />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
