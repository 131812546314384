import React, { useRef } from "react";
import {
  Certificate,
  CertificateForm,
  PlayButton,
  PlaySection,
  TopSection,
  UsersCard,
  VideoAbout,
  VideoSection,
  Wrapper,
} from "./index.style";
import { UserCardData } from "../../assets/data";
import Users from "../../components/cards/Users";
import { Button, Col, Form, Input, InputNumber, Row } from "antd";
import AboutSection from "../../components/aboutSection";
import JubileeCertificate from "../../components/Certificate/index.jsx";
import { useAuth } from "../../context/AuthContext";
import html2canvas from "html2canvas";
import { db } from "../../index.js";
import { Timestamp, doc, setDoc } from "firebase/firestore/lite";

const Home = () => {
  const { certificateFormData, setCertificateFormData } = useAuth();

  const [form] = Form.useForm();
  const pdfRef = useRef();

  const onFinish = async (values) => {
    setCertificateFormData(values);
    // form.resetFields();

    await setDoc(doc(db, "user_certificate", values.name), {
      ...values,
      timeStamp: Timestamp.fromDate(new Date()),
    });

    setTimeout(async () => {
      const input = pdfRef.current;
      try {
        const canvas = await html2canvas(input);
        const image = canvas.toDataURL("image/png");

        const link = document.createElement("a");
        link.href = image;
        link.download = "certificate.png";
        link.click();
      } catch (error) {
        console.error("Error generating PNG:", error);
      }
    }, 4000);
  };
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      address: "${label} is not a valid address!",
      age: "${label} is not a valid Age!",
      website: "${label} is not a valid website!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <>
      <Wrapper id="home_page">
        <TopSection>
          <img className="webImg" src="/images/new-banner.jpg" alt="" />
          <img className="mobileImg" src="/images/new-banner.jpg" alt="" />
        </TopSection>
        <UsersCard>
          <Row align="middle">
            {UserCardData.map((item, i) => (
              <Col xs={{ span: 12 }} lg={{ span: 6 }} key={i}>
                <Users data={item} />
              </Col>
            ))}
          </Row>
        </UsersCard>
        <AboutSection />
        <VideoSection>
          <Row gutter={26} align="middle">
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="adminImg mobileImg" style={{ margin: "auto" }}>
                <img src="./images/about.png" alt="" />
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <VideoAbout>
                <div className="aboutText">
                  <h1>
                    SEEDING <br /> THE FUTURE <br />
                    SINCE <strong> 1973</strong>
                  </h1>
                  <p>
                    The commendable contributions of each member of Nuziveedu
                    Seeds have been the cornerstone of our journey.
                  </p>
                </div>
                <h3>Mandava Prabhakar Rao</h3>
                <div>
                  <span>Chairman and Managing Director</span>
                </div>
              </VideoAbout>
            </Col>
          </Row>
        </VideoSection>
        <PlaySection>
          <div>
            <p>
              Participate and engage in this jubilant festivity of Nuziveedu
              Seeds and get your chance to win exciting lucky draws!
            </p>
            <PlayButton to="/celebrate">PLAY</PlayButton>
          </div>
        </PlaySection>
        <Certificate id="certificate">
          <Row align="bottom">
            <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }}>
              <div className="userAvatar">
                <img src="./images/new-c.png" alt="" />
              </div>
            </Col>
            <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
              <h1 style={{ textTransform: "uppercase" }}>
                Win the Golden
                <br />
                Jubilee Certificate
              </h1>
              <p>
                Participate in our jubilee celebrations and get an exclusive
                Golden Jubilee certificate as you win!
              </p>
              <CertificateForm>
                <Form
                  form={form}
                  layout="vertical"
                  name="nest-messages"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                >
                  <h3>Fill up your information</h3>
                  <Form.Item name="name" rules={[{ required: true }]}>
                    <Input placeholder="Name" />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    rules={[{ type: "number", required: true }]}
                  >
                    <InputNumber placeholder="Phone Number" />
                  </Form.Item>
                  <Form.Item name="district" rules={[{ required: true }]}>
                    <Input placeholder="Village / City / District / State" />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit">Download Certificate</Button>
                  </Form.Item>
                </Form>
              </CertificateForm>
            </Col>
          </Row>
          <div className="JubileeCertificate">
            <JubileeCertificate pdfRef={pdfRef} />
          </div>
        </Certificate>
      </Wrapper>
    </>
  );
};

export default Home;
