import React from "react";
import { AboutText, PlaySection, Wrapper } from "./index.style";
import { PlayButton } from "../../Home/index.style";
import { BsInfoCircleFill } from "react-icons/bs";
import {
  FormContainer,
  ModalBody,
  ModalHeader,
  SubmitBtn,
} from "../../../common/index.style";
import { useNavigate } from "react-router-dom";

const Instructions = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <PlaySection>
        <FormContainer>
          <ModalHeader>
            <h2>INSTRUCTIONS</h2>
          </ModalHeader>
          <ModalBody>
            <AboutText>
              <ol>
                <li>
                  Nuziveedu Seeds will be organizing a{" "}
                  <a href="#">
                    <strong style={{ color: "#EE1D25" }}>Lucky Draw</strong>
                  </a>{" "}
                  every month as part of the celebration of its 50th
                  anniversary.
                </li>
                <li>
                  Visit the "Participate and Win" section on the Nuziveedu Seeds
                  website.
                </li>
                <li>Look for the "Enter" button and click on it.</li>
                <li>Fill in your details accurately in the given fields</li>
                <li>
                  After entering your details, submit the form to officially
                  enter the competition.
                </li>
                <li>
                  If you are selected as the lucky winner in the draw, you will
                  receive exciting prizes.
                </li>
                <li>
                  Along with the prize, you will also be awarded a winning
                  certificate from Nuziveedu Seeds.
                </li>
              </ol>
            </AboutText>
            <SubmitBtn onClick={() => navigate("/quiz")}>PLAY</SubmitBtn>
          </ModalBody>
        </FormContainer>
      </PlaySection>
    </Wrapper>
  );
};

export default Instructions;
