import React from "react";
import { PlayButton, PlaySection, Wrapper } from "./index.style";

const Celebrate = () => {
  return (
    <Wrapper>
      <PlaySection>
        <div>
          <p>
            Participate and engage in this jubilant festivity of Nuziveedu Seeds
            and get your chance to win exciting lucky draws!
          </p>
          <PlayButton to="/sign_up_form">ENTER</PlayButton>
        </div>
      </PlaySection>
    </Wrapper>
  );
};

export default Celebrate;
