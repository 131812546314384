import { Tabs } from "antd";
import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 2em 7em;
  background-image: linear-gradient(
    0deg,
    transparent,
    transparent,
    transparent,
    transparent,
    #00000020
  );
  h1 {
    color: #000;
    text-align: center;
    // font-family: "Helvetica Now Display";
    font-size: 68px;
  }
  @media (max-width: 992px) {
    & {
      padding: 2em 2em;
      h1 {
        font-size: 38px;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 2em 1em;
    }
  }
`;
export const TopHeading = styled("div")`
  margin-top: 2em;
  // h1 {
  //   font-size: 100px;
  // }
  @media (max-width: 992px) {
    & {
      margin-top: 1em;
    }
  }
`;
export const CustomizeTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-top: 2em;
    margin-bottom: 5em;
    &::before {
      display: none !important;
    }
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-tab,
  .ant-tabs-tab-active {
    font-family: Helvetica-Now-Display-bd;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 1.5em;
    height: 50px;
    flex-shrink: 0;
    border-radius: 54px;
    text-align: center;
    font-size: 25px;
    background: transparent;
    color: #000000;
  }
  .ant-tabs-tab-active {
    background: #ee1d25;
    color: #fff !important;
    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
  .ant-tabs-tabpane {
    text-align: center;
    img {
      width: 100%;
      // margin-bottom: 2em;
    }
  }
  @media (max-width: 992px) {
    & {
      .ant-tabs-nav {
        margin-bottom: 0em;
      }
      .ant-tabs-tab,
      .ant-tabs-tab-active {
        padding: 0em 2em;
        // width: 80px;
        height: 40px;
        font-size: 14px;
        margin-bottom: 1.5em;
      }
      .ant-tabs-nav-list {
        flex-wrap: wrap !important;
        justify-content: space-around !important;
      }
    }
  }
  @media (max-width: 382px) {
    & {
      .ant-tabs-tab {
        margin: 0 0 1em 0px !important;
      }
    }
  }
`;

export const ImageContainer = styled("div")`
  margin-bottom: 2em;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  img {
    width: 100%;
    border-radius: 15px;
  }
  .ant-image-mask {
    border-radius: 15px;
  }
  @media (max-width: 1340px) {
    & {
      margin-bottom: 1.5em;
      height: 200px;
    }
  }
  @media (max-width: 992px) {
    & {
      height: 150px;
      margin-bottom: 0.8em;
    }
  }
  @media (max-width: 640px) {
    & {
      height: 110px;
    }
  }
`;
