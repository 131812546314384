import React from "react";
import {
  CardDisc,
  FarmerAvatar,
  FarmerCardContainer,
  FarmerCardFooter,
  FarmerCardHeader,
} from "./index.style";

const FarmerCard = ({ data }) => {
  return (
    <FarmerCardContainer>
      <FarmerCardHeader>
        <FarmerAvatar>
          <img src={data.image} alt="" />
        </FarmerAvatar>
      </FarmerCardHeader>
      <CardDisc>
        <p>{data.disc}</p>
      </CardDisc>
      <FarmerCardFooter>
        <div className="dots">
          <div>
            <img src="./images/dots.svg" alt="" />
          </div>
        </div>
        <div>
          <h6>{data.name_title}</h6>
          <span>{data.bio}</span>
        </div>
      </FarmerCardFooter>
    </FarmerCardContainer>
  );
};

export default FarmerCard;
