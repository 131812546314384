import React, { useRef } from "react";
import {
  AboutFarmer,
  CustomSlider,
  Employee,
  EmployeeSection,
  FarmerSlider,
  FarmersSection,
  SiteVideo,
  TopSection,
  Wrapper,
} from "./index.style";
import AboutSection from "../../components/aboutSection";
import YouTube from "react-youtube";
import { VideoAbout, VideoSection } from "../Home/index.style";
import { Col, Row } from "antd";
import Slider from "react-slick";
import EmployeeCard from "../../components/cards/EmployeeCard";
import FarmerCard from "../../components/cards/FarmerCard";
import { FarmerCardData, employeesData } from "../../assets/data";

const Seeding = () => {
  const embedUrl = `https://youtu.be/TD4q-RIM5R0?si=KH-W761bN5zBxwHe`;
  const ref = useRef();
  const opts = {
    playerVars: {
      autoplay: 0, // 1 for autoplay
    },
  };
  const employee = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const farmerSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <TopSection>
        <img src="/images/seed-bg.jpg" alt="" />
        <h1>
          SEEDING <br />
          THE FUTURE
        </h1>
      </TopSection>
      <SiteVideo>
        <div className="video-container">
          <YouTube
            ref={ref}
            videoId="TD4q-RIM5R0?si=KH-W761bN5zBxwHe"
            opts={opts}
          />
        </div>
      </SiteVideo>
      <AboutSection />
      <VideoSection
        style={{
          backgroundImage:
            "linear-gradient(0deg,#00000020,transparent, transparent, transparent, transparent)",
        }}
      >
        <Row justify="center" align="center">
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            {/* <YouTube
              ref={ref}
              videoId="LXb3EKWsInQ?si=8FUV-TsN7Zhs60n2"
              opts={opts}
            /> */}
            <div className="adminImg" style={{ margin: "auto" }}>
              <img
                style={{ borderRadius: "23px" }}
                src="./images/founder.PNG"
                alt=""
              />
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <VideoAbout>
              <div className="aboutText">
                <h1></h1>
                <p style={{ fontWeight: "900" }}>
                  At <strong style={{ color: "red" }}> Nuziveedu,</strong> we
                  believe that our happiness lies in the contentment of our
                  farmers. If the farmers are happy, our whole country will be
                  happy!
                </p>
              </div>
              <h3>Shri Venkat Ramaiah Garu</h3>
              <div>
                <span>Founder</span>
              </div>
            </VideoAbout>
          </Col>
        </Row>
      </VideoSection>
      <EmployeeSection>
        <h1>OUR PILLARS</h1>
        <p className="subHeading">
          Hear from our employees about their experiences at Nuziveedu.
        </p>
        <CustomSlider>
          <Slider {...settings}>
            <div>
              <Employee>
                <img src="/images/em-1.png" alt="" />
              </Employee>
            </div>
            <div>
              <Employee>
                <img src="/images/em-2.png" alt="" />
              </Employee>
            </div>
            <div>
              <Employee>
                <img src="/images/em-3.png" alt="" />
              </Employee>
            </div>
            <div>
              <Employee>
                <img src="/images/em-4.png" alt="" />
              </Employee>
            </div>
            <div>
              <Employee>
                <img src="/images/em-5.png" alt="" />
              </Employee>
            </div>
            <div>
              <Employee>
                <img src="/images/em-6.png" alt="" />
              </Employee>
            </div>
            <div>
              <Employee>
                <img src="/images/em-7.png" alt="" />
              </Employee>
            </div>
            <div>
              <Employee>
                <img src="/images/em-8.png" alt="" />
              </Employee>
            </div>
            <div>
              <Employee>
                <img src="/images/em-9.png" alt="" />
              </Employee>
            </div>
            <div>
              <Employee>
                <img src="/images/em-10.png" alt="" />
              </Employee>
            </div>
            <div>
              <Employee>
                <img src="/images/em-11.png" alt="" />
              </Employee>
            </div>
            <div>
              <Employee>
                <img src="/images/em-12.png" alt="" />
              </Employee>
            </div>
          </Slider>
        </CustomSlider>
        <Slider {...employee}>
          {" "}
          {employeesData.map((item, i) => (
            <div key={1}>
              <EmployeeCard data={item} />
            </div>
          ))}
        </Slider>
      </EmployeeSection>
      <FarmersSection>
        <Row align="middle">
          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <AboutFarmer>
              <h1>
                Heartfelt <br />
                testimonials <br />
                from our <br />
                farmers
              </h1>
              <p>
                Heartwarming tales by our farmers as they share their experience
                with Nuziveedu Seeds! Throughout our 50 years of journey, we
                have consistently delivered advanced seeds to our farmers.
              </p>
            </AboutFarmer>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 14 }}>
            <FarmerSlider>
              <Slider {...farmerSettings}>
                {FarmerCardData.map((item, i) => (
                  <div key={i}>
                    <FarmerCard data={item} />
                  </div>
                ))}
              </Slider>
            </FarmerSlider>
          </Col>
        </Row>
      </FarmersSection>
    </Wrapper>
  );
};

export default Seeding;
