import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled("div")``;
export const TopSection = styled("div")`
  img {
    width: 100%;
  }
  .webImg {
    display: block;
  }
  .mobileImg {
    display: none;
  }
  @media (max-width: 992px) {
    & {
      .webImg {
        display: none;
      }
      .mobileImg {
        display: block;
      }
    }
  }
`;
export const UsersCard = styled("div")`
  border: 1px solid #e4e4e4;
`;

export const VideoSection = styled("div")`
  padding: 9em 7em;
  img {
    max-width: 100%;
  }
  .adminImg {
    width: 85%;
  }
  @media (max-width: 1440px) {
    & {
      padding: 6em 2em;
      .adminImg {
        width: 90% !important;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      .adminImg {
        width: 100% !important;
      }
      .mobileImg {
        margin-left: -7px !important;
      }
    }
  }
`;
export const VideoAbout = styled("div")`
  width: 75%;
  margin: auto;
  h1 {
    font-size: 3.8em;
    font-family: Helvetica-Now-Display-it-ebd;
    line-height: 1.3;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    strong {
      color: #ee1d25;
      font-family: Helvetica-Now-Display-it-ebd;
      letter-spacing: -4px;
    }
  }
  h3 {
    font-size: 2.5em;
    font-family: Helvetica-Now-Display-it-ebd;
    line-height: 1.2;
    margin-bottom: 0;
  }
  .aboutText {
    padding: 2em 0;
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      width: 100px;
      height: 100px;
      background-image: url(./images/top.png);
      background-repeat: no-repeat;
      position: absolute;
      top: -10px;
      left: -49px;
      z-index: -1;
    }
    &::after {
      content: "";
      display: inline-block;
      width: 100px;
      height: 100px;
      background-image: url(./images/last.png);
      background-repeat: no-repeat;
      position: absolute;
      bottom: -30px;
      right: 0;
      z-index: -1;
    }
  }
  p {
    font-size: 1.6em;
    color: #000000;
    margin-bottom: 0;
  }
  span {
    font-size: 1.6em;
    color: red;
    font-style: italic;
  }
  @media (max-width: 992px) {
    & {
      width: 90%;
      margin-top: 2em;
      h1 {
        width: 72%;
        font-size: 34px;
        br {
          display: none;
        }
      }
      .aboutText {
        &::after {
          width: 77px;
          height: 63px;
          background-size: 100% 100%;
          bottom: -15px;
        }
        &::before {
          background-size: 100% 100%;
          width: 77px;
          height: 63px;
          left: -35px;
          top: 0;
        }
      }
      p {
        font-size: 1em;
        color: #000000;
        margin-bottom: 0;
      }
      h3 {
        font-size: 17px;
      }
      span {
        font-size: 14px;
      }
    }
  }
`;

export const PlaySection = styled("div")`
  padding: 3em 7em;
  background-image: url(./images/new-play.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 871px;
  display: flex;
  justify-content: center;
  align-items: end;
  text-align: center;
  p {
    color: #ffffff;
    max-width: 800px;
    font-size: 1.6em;
  }
  @media (max-width: 1440px) {
    height: 700px;
    & {
      padding: 3em 2em 0.5em;
    }
  }
  @media (max-width: 992px) {
    & {
      background-image: url(./images/m-play.jpg);
      background-size: 100% 100%;
      height: 300px;
      padding: 3em 2em 0.5em;
      p {
        font-size: 0.9em;
      }
    }
  }
`;
export const PlayButton = styled(Link)`
  width: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #fff;
  color: #ee1d25;
  font-size: 50px;
  margin: 1em auto;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  font-family: Helvetica-Now-Display-it-ebd;
  &:hover {
    background: #fff;
    color: #ee1d25;
  }
  @media (max-width: 992px) {
    & {
      width: 106px;
      height: 36px;
      font-size: 20px;
      border-radius: 7px;
    }
  }
`;

export const Certificate = styled("div")`
  padding: 3em 5em 0;
  background-image: url(./images/bg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  h1 {
    font-family: Helvetica-Now-Display-it-ebd;
    font-size: 68px;
    line-height: 1.3;
    margin-bottom: 0;
  }
  h3 {
    font-family: Helvetica-Now-Display-it-ebd;
    font-size: 31px;
    color: #ee1d25;
    margin-bottom: 1em;
  }
  .userAvatar {
    width: 85%;
    margin-left: auto;
  }
  p {
    font-size: 21px;
  }
  .JubileeCertificate {
    position: absolute;
    left: -100%;
    z-index: -111;
  }
  @media (max-width: 1670px) {
    & {
      .userAvatar {
        width: 100%;
      }
    }
  }
  @media (max-width: 1440px) {
    & {
      h1 {
        font-size: 55px;
      }
      h3 {
        font-size: 31px;
      }
      p {
        font-size: 21px;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      padding: 3em 1em 0;
      text-align: center;
      h1 {
        font-size: 35px;
      }
      h3 {
        font-size: 17px;
      }
      p {
        font-size: 13px;
      }
    }
  }
`;
export const CertificateForm = styled("div")`
  margin-bottom: 10%;
  input {
    width: 75%;
    height: 61px;
    border-radius: 15px;
    border: 1px solid #ed3438;
    background: #fff;
    padding-left: 1.5em;
    font-size: 20px;
    &::placeholder {
      color: #cecece;
      font-size: 20px;
    }
  }
  .ant-input-number {
    border: none;
    width: 75%;
    border-radius: 15px;
    box-shadow: none !important;
    input {
      overflow: hidden;
      width: 100%;
    }
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
  .ant-btn {
    height: 61px;
    padding: 0.2em 2.5em;
    border-radius: 15px;
    background: #ee1d25;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    span {
      font-family: Helvetica-Now-Display-it-ebd;
      color: #fff;
      font-size: 22px;
      line-height: 0;
    }
  }
  @media (max-width: 1440px) {
    & {
      input {
        width: 90%;
      }
      .ant-input-number {
        width: 90%;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      padding: 2em 2em 0;
      input {
        width: 100%;
        font-size: 12px;
        height: 36px;
        border-radius: 7px;
        padding-left: 1em;
        font-size: 12px;

        &::placeholder {
          color: #cecece;
          font-size: 12px;
        }
      }
      .ant-input-number {
        width: 100%;
        font-size: 12px;
        height: 36px;
        border-radius: 7px;
      }
      .ant-btn {
        height: 30px;
        border-radius: 7px;
        span {
          font-size: 12px;
        }
      }
    }
  }
`;
