import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { useAuth } from "../../context/AuthContext";

const QuizForm = ({ quizData, selectedOption, onOptionChange }) => {
  const { setDisabledContent } = useAuth();
  console.log("Your score:", selectedOption);
  if (selectedOption) {
    setDisabledContent(false);
  }

  return (
    <>
      {/* {quizData.map((item, i) => ( */}
      <Container>
        <Heading>
          <p>
            {quizData.quiz_text} <img src={quizData.quiz_avatar} alt="" />
          </p>
        </Heading>
        {/* <Avatar>
          <img src="/images/layer-1.png" alt="" />
          <img src="/images/+.svg" alt="" />
          <img src="/images/shoes.png" alt="" />
          <img src="/images/=.svg" alt="" />
          <div>
            <span>Ab Sab Fit Hai</span>
          </div>
        </Avatar> */}
        <Options>
          <Row justify="center" align="middle" gutter={16}>
            {quizData.options.map((option, index) => (
              <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                <Answer>
                  {option.option_avatar === "" ? (
                    ""
                  ) : (
                    <img src={option.option_avatar} alt="" />
                  )}
                  <div>
                    <label>
                      <div style={{ marginBottom: "0.5em" }}>
                        <input
                          type="radio"
                          required
                          value={option}
                          checked={selectedOption === option.option_label}
                          onChange={() => onOptionChange(option.option_label)}
                        />
                      </div>
                      <div>{option.option_label}</div>
                    </label>
                  </div>
                </Answer>
              </Col>
            ))}
          </Row>
        </Options>
      </Container>
      {/* ))} */}
    </>
  );
};

export default QuizForm;

const Container = styled("div")`
  padding: 1em 2em;
`;
const Heading = styled("div")`
  margin-bottom: 2em;
  height: 150px;
  p {
    color: #000;
    text-align: center;
    font-size: 30px;
  }
  @media (max-width: 992px) {
    & {
      margin-bottom: 1em;
      height: 100px;
      p {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      margin-bottom: 3em;
      height: 150px;
      p {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 375px) {
    & {
      margin-bottom: 1em;
      height: 130px;
    }
  }
`;
// s
const Options = styled("div")`
  text-align: center;
  margin-top: 3em;
  @media (max-width: 992px) {
    & {
      margin-top: 1em;
    }
  }
`;
const Answer = styled("div")`
  display: flex;
  // height: 100px;
  justify-content: center;
  align-items: center;
  img {
    height: 90px;
    margin-right: 0.5em;
  }
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  input {
    margin-right: 0em;
    cursor: pointer;
    display: block;
    &:focus {
      outline: none !important;
    }
  }
  label {
    margin: 1em 0em;
    font-size: 20px;
    color: #000000;
    font-family: Arial;
    font-weight: 900;
  }
  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 35px;
    height: 35px;
    padding: 4px;
    background-clip: content-box;
    border: 2px solid #ee1d25;
    background-color: #ffffff;
    border-radius: 50%;
  }

  input[type="radio"]:checked {
    background-image: linear-gradient(
      90deg,
      #65d38a 0%,
      #65d38a 28.01%,
      #00a956 67.02%,
      #0aa057 83.02%,
      #16985a 95.03%
    );
    border: 2px solid #ee1d25;
  }
  @media (max-width: 992px) {
    & {
      label {
        font-size: 20px;
      }
      img {
        height: 60px;
      }
      input[type="radio"] {
        margin: auto;
      }
    }
  }
  @media (max-width: 640px) {
    & {
      label {
        font-size: 16px;
      }
      img {
        height: 40px;
      }
      input[type="radio"] {
        margin: auto;
        width: 20px;
        height: 20px;
        padding: 2px;
      }
    }
  }
  @media (max-width: 440px) {
    & {
      label {
        font-size: 13px;
      }
    }
  }
`;
