import { Button } from "antd";
import styled from "styled-components";

export const FormContainer = styled("div")`
  width: 1104.246px;
  height: 588.028px;
  background-image: url(./images/modal.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    & {
      width: 100%;
      height: auto;
      background-image: url(./images/m-modal.png);
    }
  }
`;
export const ModalHeader = styled("div")`
  height: 73px;
  h2 {
    text-align: center;
    margin-bottom: 0;
    color: #fff;
    font-size: 45px;
  }
  @media (max-width: 992px) {
    & {
      height: 36px;
      h2 {
        font-size: 20px;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      height: 30px;
      h2 {
        font-size: 20px;
      }
    }
  }
  @media (max-width: 375px) {
    & {
      height: 21px;
      h2 {
        font-size: 16px;
      }
    }
  }
`;
export const ModalBody = styled("div")`
  width: 1092.246px;
  border-radius: 24px;
  padding: 3em 2em;
  margin: auto;
  height: 511.028px;
  background: #ffffff;
  h3 {
    text-align: center;
    margin: 0.2em 0 1em;
    color: #000;
    font-size: 40px;
  }
  @media (max-width: 1420px) {
    & {
      height: 504.028px;
    }
  }
  @media (max-width: 1360px) {
    & {
      height: 510.028px;
    }
  }
  @media (max-width: 992px) {
    & {
      width: 100%;
      height: auto;
      padding: 1em 0;
      background: #ffffff;
      // background: linear-gradient(
      //   90deg,
      //   #c28022 0%,
      //   #b46a1a 7%,
      //   #ffe777 16%,
      //   #f4d165 21%,
      //   #d79a38 30%,
      //   #ce8728 34%,
      //   #ce8728 62%,
      //   #ffc049 75%,
      //   #ce8825 86%,
      //   #ca8423 87%,
      //   #ffc654 93%,
      //   #a75d0d 100%
      // );
      h3 {
        font-size: 22px;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      width: 352px;
      height: 496px;
      border-radius: 15px;
    }
  }
  @media (max-width: 440px) {
    & {
      width: 99%;
      height: 495px;
      border-radius: 11px;
      margin: 2px auto;
    }
  }
  @media (max-width: 375px) {
    & {
      height: auto;
    }
  }
`;
export const NextBtn = styled(Button)`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 157px;
  height: 61px;
  padding: 0;
  // flex-shrink: 0;
  border-radius: 15px;
  background: #ee1d25;
  border: 1px solid #ee1d25;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
  font-size: 34px;
  cursor: pointer;
  font-family: Helvetica-Now-Display-it-ebd;
  transition: 300ms;
  svg {
    font-size: 54px;
  }
  &:hover {
    background: #ee1d25;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    border: 1px solid #ee1d25;
  }
  @media (max-width: 1320px) {
    & {
      width: 133px;
      height: 54px;
      justify-content: center;
      border-radius: 7px;
      margin-bottom: 0 !important;
      svg {
        font-size: 40px;
      }
      span {
        font-size: 22px;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      width: 133px;
      height: 44px;
      border-radius: 7px;
      svg {
        font-size: 35px;
      }
      span {
        font-size: 22px;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      width: 90px;
      height: 46px;
      border-radius: 7px;
      svg {
        font-size: 30px;
      }
      span {
        font-size: 18px;
      }
    }
  }
`;
export const SubmitBtn = styled(Button)`
  width: 226px;
  height: 76px;
  margin: auto;
  border-radius: 15px;
  background: #ee1d25;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  span {
    color: #fff;
    font-size: 35px;
    font-family: Helvetica-Now-Display-it-ebd;
  }
  &:focus,
  &:hover {
    background: #ee1d25 !important;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    span {
      color: #fff;
      // font-size: 35px;
    }
  }
  @media (max-width: 992px) {
    & {
      width: 133px;
      height: 44px;
      border-radius: 7px;
      span {
        font-size: 22px;
      }
    }
  }
`;
