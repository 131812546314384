import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getFirestore } from "firebase/firestore/lite";
import { initializeApp } from "firebase/app";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAQMtXUW3I5suZ6lReVxUT9BHujR-w4n8Y",
  authDomain: "nuziveedu.firebaseapp.com",
  projectId: "nuziveedu",
  storageBucket: "nuziveedu.appspot.com",
  messagingSenderId: "628490567848",
  appId: "1:628490567848:web:cbacd76dc99deec7c8029d",
  measurementId: "G-R4QLN11RWS",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
