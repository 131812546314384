import styled from "styled-components";

export const Wrapper = styled("div")``;
export const PlaySection = styled("div")`
  padding: 2em 7em 3em;
  background-image: url(./images/th.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: end;
  text-align: center;
  p {
    color: #ffffff;
    margin: 0.7em auto;
    max-width: 800px;
    font-size: 2.5em;
  }
  @media (max-width: 1600px) {
    & {
      p {
        font-size: 2em;
      }
      height: 900px;
    }
  }
  @media (max-width: 1370px) {
    & {
      padding: 8em 7em 0em;
      height: 850px;
      align-items: center;
      p {
        font-size: 1.5em;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      padding: 3em 4em;
      align-items: center;
      background-image: url(/images/thank-you-1.jpg);
      height: 95.8vh;
      .content {
        padding-top: 12em;
      }
      p {
        margin-bottom: 0em;
        font-size: 1.3em;
      }
      .downloadBtns {
        margin: 2em;
        flex-direction: column;
      }
    }
  }
`;
export const Border = styled("div")`
  background-image: linear-gradient(
    90deg,
    #522f20 0%,
    #c28022 4%,
    #b46a1a 7%,
    #ffe777 10%,
    #f0ca5f 16%,
    #dda541 23%,
    #d28f2e 30%,
    #ce8728 34%,
    #ce8728 62%,
    #ffc049 75%,
    #ce8825 86%,
    #ca8423 87%,
    #ffc654 93%,
    #a75d0d 96%,
    #522f20 100%
  );
  width: 907px;
  height: 4px;
  @media (max-width: 992px) {
    & {
      width: 100%;
      height: 2px;
    }
  }
`;
export const SocialLinks = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  a {
    font-size: 1.5em;
    margin: 0.5em;
    display: inline-flex;
  }
  .home-btn {
    width: 272px;
    height: 92px;
    border-radius: 15px;
    background: #fff;
    color: #ee1d25;
    line-height: 1;
    font-size: 32px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  }
  @media (max-width: 1440px) {
    & {
      a {
        width: 66px;
      }
      .home-btn {
        width: 238px;
        height: 71px;
        font-size: 31px;
      }
    }
  }
  @media (max-width: 1370px) {
    & {
      margin-bottom: 0em;
      a {
        width: 56px;
      }
      .home-btn {
        width: 180px;
        height: 61px;
        font-size: 22px;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      a {
        width: 36px;
        img {
          width: 36px;
        }
      }
      .home-btn {
        width: 132px;
        border-radius: 7px;
        height: 44px;
        font-size: 18px;
      }
    }
  }
`;
