import styled from "styled-components";

export const Wrapper = styled("div")``;
export const PlaySection = styled("div")`
  padding: 3em 7em;
  background-image: url(./images/result.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: end;
  div {
    text-align: center;
  }
  p {
    color: #ffffff;
    max-width: 800px;
    font-size: 1.5em;
  }
  @media (max-width: 1440px) {
    & {
      height: 800px;
      padding: 6em 2em;
    }
  }
  @media (max-width: 992px) {
    & {
      height: 100vh;
      align-items: center;
      padding: 3em 2em;
      background-image: url(/images/play-4.jpg);
    }
  }
`;
export const Numbers = styled("h2")`
  color: #fff;
  text-align: center;
  text-shadow: 0px 15px 25px rgba(0, 0, 0, 0.4);
  // font-family: "Helvetica Now Display";
  margin-bottom: 0em;
  font-size: 200px;
  @media (max-width: 1440px) {
    & {
      font-size: 200px;
    }
  }
  @media (max-width: 1340px) {
    & {
      font-size: 180px;
    }
  }
  @media (max-width: 992px) {
    & {
      font-size: 150px;
    }
  }
  @media (max-width: 662px) {
    & {
      font-size: 80px;
      margin-bottom: 1em;
    }
  }
`;
