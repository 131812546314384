import styled from "styled-components";

export const Wrapper = styled("div")``;
export const AboutText = styled("div")`
  p {
    color: #000000;
    font-size: 20px;
  }
`;
export const PlaySection = styled("div")`
  padding: 3em 7em;
  background-image: url("/images/quiz_banner.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  @media (max-width: 1440px) {
    & {
      height: 800px;
    }
  }

  @media (max-width: 992px) {
    & {
      padding: 3em 2em;
      height: 100vh;
      background-image: url(/images/play-3.jpg);
    }
  }
`;
export const FooterContent = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1104.246px;
  margin: 2em auto;

  @media (max-width: 1260px) {
    & {
      width: 100% !important;
    }
  }
  @media (max-width: 992px) {
    & {
      width: 90%;
      margin-top: 2em;
      margin: 2em auto;
    }
  }
  @media (max-width: 768px) {
    & {
      width: 100%;
      margin-top: 2em;
    }
  }
`;
export const PageCount = styled("div")`
  color: #fff;
  font-size: 34px;
  font-family: Helvetica-Now-Display-it-ebd;
  @media (max-width: 992px) {
    & {
      font-size: 22px;
    }
  }
`;
