import React from "react";
import styled from "styled-components";
import { useAuth } from "../../context/AuthContext";

// Define the Certificate component
const JubileeCertificate = ({ pdfRef }) => {
  const { certificateFormData } = useAuth();
  console.log("DAta =====>>", certificateFormData);
  return (
    <CertificateContainer ref={pdfRef}>
      <div className="content">
        <img src="/images/heading.svg" alt="" />
        <h5>Awarded to</h5>
        <h2>{certificateFormData.name}</h2>
        {/* <p className="graduate-para">
          in recognition of your exceptional contribution to organise
        </p> */}
        <span className="aboutText">
          Your trust and commitment of excellence have been the foundation{" "}
          <br />
          of our success for over 50 years.
          <br />
          Let us work towards building a better future together.
        </span>
        {/* <div className="signArea">
          <img src="/images/tnx.png" alt="" />
          <img src="/images/sign.svg" alt="" />
          <h4>Mandava Prabhakar Rao</h4>
          <p>Chairman and Managing Director</p>
        </div> */}
        <h6>Team Nuziveedu</h6>
      </div>
    </CertificateContainer>
  );
};

// Export the Jubilee Certificate component
export default JubileeCertificate;

const CertificateContainer = styled.div`
  background-image: url("/images/new-bg.jpg"); / Adjust the path to your image /
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1440px;
  height: 1024px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
 
  .content {
    h5 {
      font-family: Helvetica-Now-Display-re;
      font-size: 32px;
      margin: 1em 0 0;
    }
    h2 {
      font-size: 70px;
      color: #ED1C24;
      font-weight: 600;
      font-family: Helvetica-Now-Display-re;
      margin-bottom: 0.5em;
      text-transform: capitalize;
    }
    h6,
    .graduate-para {
      font-size: 20px;
      text-align: center;
      font-family: Helvetica-Now-Display-bd;
      width: 1050px;
      line-height: 36.75px;
      margin: 0 auto;
    }
    h6 {
      font-family: Helvetica-Now-Display-bd;
      font-size: 32px;
      margin: 3em 0 0;
      color: #ED1C24;
    }
    .aboutText {
      font-family: Helvetica-Now-Display-re;
      color: #231F20;
      font-size: 20px; 
    }
    .signArea {
      width: 300px;
      margin: 1em auto;
      img {
        display: block;
        margin-bottom: 1em;
      }
      h4 {
        font-size: 23px;
        font-family: Helvetica-Now-Display-re;
        margin-bottom: 0;
      }
      p {
        font-size: 16px;

      }
    }
   
  }
`;
