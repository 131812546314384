import styled from "styled-components";

export const Wrapper = styled("div")``;
export const TopSection = styled("div")`
  img {
    width: 100%;
  }
  h1 {
    position: absolute;
    left: 15%;
    top: 40%;
    transform: translateY(-50%);
    font-family: Helvetica-Now-Display-it-ebd;
    color: #fff;
    text-shadow: 0px 9px 10px rgba(0, 0, 0, 0.65);
    font-size: 100px;
    line-height: 1;
  }
  @media (max-width: 1440px) {
    & {
      h1 {
        font-size: 90px;
        left: 20%;
      }
    }
  }
  @media (max-width: 1360px) {
    & {
      h1 {
        font-size: 80px;
        left: 10%;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      h1 {
        font-size: 32px;
        left: 24%;
        top: 106px;
      }
    }
  }
`;

export const SiteVideo = styled("div")`
  text-align: center;
  padding: 4em 7em;
  .video-container {
    overflow: hidden;
    border-radius: 32px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  }
  iframe {
    width: 100%;
    height: 678px;
  }
  @media (max-width: 992px) {
    & {
      padding: 3em 2em;
      iframe {
        width: 100%;
        height: 300px;
      }
    }
  }
`;
export const EmployeeSection = styled("div")`
  padding: 6em 2em;
  text-align: center;
  background-image: linear-gradient(
    0deg,
    #00000020,
    transparent,
    transparent,
    transparent,
    transparent
  );
  .subHeading {
    font-size: 22px !important;
    max-width: 1100px;
    margin: 1em auto;
  }
  h1 {
    font-size: 68px;
  }
  @media (max-width: 992px) {
    & {
      padding: 3em 2em;
      h1 {
        font-size: 34px;
      }
      .subHeading {
        font-size: 10px !important;
      }
    }
  }
`;
export const CustomSlider = styled("div")`
  width: 70%;
  margin: 3em auto;
  .slick-prev,
  .slick-next {
    background-image: url(./images/left.png);
    width: 30px;
    height: 38px;
    margin: 0 1em;
    background-repeat: no-repeat;
    &::before {
      display: none;
    }
  }
  .slick-next {
    right: -60px !important;
  }
  .slick-prev {
    left: -60px !important;
  }
  .slick-next {
    background-image: url(./images/right-arrow.svg);
  }
  .slick-slider {
    // width: 1050px;
    margin: 1em auto;
  }
  @media (max-width: 1440px) {
    & {
      width: 90%;
    }
  }
  @media (max-width: 1360px) {
    & {
      width: 92%;
    }
  }
  @media (max-width: 992px) {
    & {
      width: 100%;
      .slick-next {
        width: 17px;
        height: 28px;
        background-size: 100%;
        right: -18px !important;
      }
      .slick-prev {
        width: 17px;
        height: 28px;
        background-size: 100%;
        left: -18px !important;
      }
    }
  }
  @media (max-width: 442px) {
    & {
      width: 100%;
      .slick-next {
        width: 13px;
        height: 25px;
        background-size: 100%;
      }
      .slick-prev {
        width: 13px;
        height: 25px;
      }
    }
  }
`;
export const Employee = styled("div")`
  width: 156px;
  overflow: hidden;
  margin: 0em auto;
  height: 156px;
  border-radius: 50%;
  background: #d9d9d9;
  @media (max-width: 992px) {
    & {
      width: 86px;
      height: 86px;
    }
  }
  @media (max-width: 440px) {
    & {
      width: 70px;
      height: 70px;
    }
  }
`;
export const FarmersSection = styled("div")`
  padding: 2em 7em;
  @media (max-width: 992px) {
    & {
      padding: 2em 2em;
    }
  }
`;
export const AboutFarmer = styled("div")`
  max-width: 500px;
  h1 {
    color: #000;
    font-size: 72px;
    line-height: 1.3;
    text-transform: uppercase;
  }
  p {
    max-width: 400px;
    font-size: 20px;
  }
  @media (max-width: 1440px) {
    & {
      max-width: 400px;
      h1 {
        color: #000;
        font-size: 58px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      max-width: 400px;
      text-align: center;
      h1 {
        font-size: 36px;
        br {
          display: none;
        }
      }
      p {
        font-size: 14px;
      }
    }
  }
`;
export const FarmerSlider = styled("div")`
  margin: 3em auto;
  .slick-prev,
  .slick-next {
    background-image: url(./images/left.png);
    width: 30px;
    height: 38px;
    margin: 0 1em;
    background-repeat: no-repeat;
    &::before {
      display: none;
    }
  }
  .slick-next {
    right: -60px !important;
  }
  .slick-prev {
    left: -60px !important;
  }
  .slick-next {
    background-image: url(./images/right-arrow.svg);
  }
  .slick-slider {
    // width: 1050px;
  }
  @media (max-width: 992px) {
    & {
      width: 100%;
      .slick-next {
        width: 17px;
        height: 28px;
        background-size: 100%;
        right: -18px !important;
      }
      .slick-prev {
        width: 17px;
        height: 28px;
        background-size: 100%;
        left: -18px !important;
      }
    }
  }
`;
