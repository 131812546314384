import styled from "styled-components";

export const Wrapper = styled("div")``;
export const TopSection = styled("div")`
  img {
    width: 100%;
  }
  h1 {
    position: absolute;
    left: 15%;
    line-height: 1;
    top: 40%;
    transform: translateY(-50%);
    font-family: Helvetica-Now-Display-it-ebd;
    color: #fff;
    text-shadow: 0px 9px 10px rgba(0, 0, 0, 0.65);
    font-size: 120px;
  }
  @media (max-width: 1470px) {
    & {
      h1 {
        font-size: 100px;
        left: 15%;
      }
    }
  }
  @media (max-width: 1360px) {
    & {
      h1 {
        font-size: 80px;
        top: 48%;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      h1 {
        font-size: 30px;
        top: 13%;
        left: 25%;
      }
    }
  }
`;
export const ContactForm = styled("div")`
  padding: 5em 7em;
  text-align: center;
  h3 {
    font-size: 40px;
  }
  p {
    font-size: 20px;
    max-width: 400px;
    margin: 1em auto;
  }
  .line {
    display: none;
  }
  input {
    // margin-top: 1em;
    height: 61px;
    border-radius: 15px;
    border: 1px solid #ed3438;
    background: #fff;
    font-size: 20px;
    &::placeholder {
      color: #cecece;
      font-size: 20px;
    }
  }

  textarea {
    height: 301px;
    font-size: 20px;
    // margin-top: 1em;
    border-radius: 15px;
    border: 1px solid #ed3438;
    background: #fff;
    &::placeholder {
      color: #cecece;
      font-size: 20px;
    }
  }
  .ant-btn {
    height: 61px;
    margin-top: 1em;
    padding: 0.2em 2em;
    border-radius: 15px;
    background: #ee1d25;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    span {
      font-family: Helvetica-Now-Display-it-ebd;
      color: #fff;
      font-size: 30px;
      //   line-height: 0;
    }
  }
  .socialLinks {
    margin: 1em auto 2em;
    a {
      margin: 0 0.5em;
    }
  }
  .contactNumber {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 7em auto 1em;
    strong {
      color: #ee1d25;
      font-family: Helvetica-Now-Display-it-ebd;
      text-align: center;
      font-size: 50px;
    }
  }
  @media (max-width: 992px) {
    & {
      padding: 3em 2em;
      .socialLinks,
      .contactNumber {
        padding: 0em 1em 1em;
        border-bottom: 1px solid #00000090;
      }
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 15px;
        margin: 2em auto;
      }
      .line {
        display: block;
      }
      .contactNumber {
        margin: 2em 1em;
        img {
          width: 50%;
        }
        strong {
          font-size: 30px;
        }
      }
      input {
        height: 36px;
        border-radius: 7px;
        font-size: 13px;
        &::placeholder {
          color: #cecece;
          font-size: 13px;
        }
      }
      textarea {
        // height: 170px !important;
        font-size: 13px;
        border-radius: 7px;
        &::placeholder {
          color: #cecece;
          font-size: 13px;
        }
      }
      .ant-btn {
        height: 36px;
        border-radius: 7px;
        span {
          font-size: 15px;
        }
      }
    }
  }
`;
