import { Form } from "antd";
import styled from "styled-components";

export const Wrapper = styled("div")``;
export const CustomForm = styled(Form)`
  margin-top: 2em;
  .ant-form-item {
    margin-top: 1em;
  }
  .ant-select,
  input {
    height: 72px;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #eba83c;
    background: #fff;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.4) inset;
  }
  .ant-select-selector {
    height: 100% !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    .ant-select-selection-item {
      display: flex !important;
      justify-content: start !important;
      align-items: center !important;
    }
    input {
      height: 100% !important;
      font-size: 20px;
    }
  }
  .ant-input-number {
    border: none;
    width: 100%;
    border-radius: 15px;
    box-shadow: none !important;
    input {
      overflow: hidden;
      width: 100%;
      font-size: 20px;
    }
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
  .ant-select-arrow {
    font-size: 1.5em !important;
    transform: rotateX(40deg);
    svg {
      color: #ee1d25;
    }
  }
  .ant-select-selection-search,
  .ant-select-selection-placeholder {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    height: 100% !important;
    color: #9a9a9a;
    font-family: "Helvetica-Now-Display-re";
    font-size: 20px;
  }
  @media (max-width: 992px) {
    & {
      padding: 0 2em;
      .ant-form-item {
        margin-top: 0.3em;
      }
      .ant-select,
      input {
        height: 42px;
        border-radius: 7px;
        font-size: 12px;
      }
      .ant-input-number {
        input {
          font-size: 12px;
        }
      }
      .ant-select-arrow {
        svg {
          font-size: 1.2em !important;
        }
      }
      .ant-select-selection-search,
      .ant-select-selection-placeholder {
        font-size: 12px;
      }
    }
  }
`;
