import React, { useState } from "react";
import { FooterContent, PageCount, PlaySection, Wrapper } from "./index.style";
import {
  FormContainer,
  ModalBody,
  ModalHeader,
  NextBtn,
} from "../../../common/index.style";
import { useNavigate } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import QuizForm from "../../../components/question";
import { quizzes } from "../../../assets/data";
import { Form } from "antd";
import { useAuth } from "../../../context/AuthContext";

const Question = () => {
  const { setScore, answers, setAnswers, disabledContent, setDisabledContent } =
    useAuth();

  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(1);

  // const handleOptionChange = (question, selectedOption) => {
  //   setAnswers((prevAnswers) => {
  //     const updatedAnswers = [...prevAnswers];
  //     updatedAnswers[quizIndex] = selectedOption;
  //     return updatedAnswers;
  //   });
  // };

  const handleOptionChange = (questionKey, selectedOption) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionKey]: selectedOption,
    }));
    // if ((selectedOption = undefined)) {
    //   setDisabledContent(true);
    // }
  };

  // console.log("Answers ===>>", answers);
  const onFinish = (values) => {
    let corrected = 0;
    quizzes.forEach((quiz, index) => {
      if (answers[`question${index + 1}`] === quiz.correctAnswer) {
        corrected += 10;
      }
    });
    if (currentStep <= 10) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(1);
    }
    setScore(corrected);
    setDisabledContent(true);

    // You can display the score or perform any other actions here
    // console.log("Your score:", corrected);
  };

  console.log("-----", disabledContent);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/result");
  };
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <PlaySection>
          <FormContainer>
            <ModalHeader>
              <h2>Question {currentStep}</h2>
            </ModalHeader>
            <ModalBody>
              {currentStep >= 1 && currentStep <= 10 && (
                <QuizForm
                  quizData={quizzes[currentStep - 1]}
                  selectedOption={answers[`question${currentStep}`]}
                  onOptionChange={(selectedOption) =>
                    handleOptionChange(`question${currentStep}`, selectedOption)
                  }
                />
              )}
            </ModalBody>
          </FormContainer>
          <FooterContent>
            <PageCount>
              {currentStep > 9 ? "" : "0"}
              {currentStep}/10
            </PageCount>
            {currentStep > 9 ? (
              <NextBtn htmlType="submit" onClick={handleNavigate}>
                Next <FiChevronRight />
              </NextBtn>
            ) : (
              <>
                {disabledContent ? (
                  <NextBtn disabled htmlType="submit">
                    Next <FiChevronRight />
                  </NextBtn>
                ) : (
                  <NextBtn htmlType="submit">
                    Next <FiChevronRight />
                  </NextBtn>
                )}
              </>
            )}
          </FooterContent>
        </PlaySection>
      </Form>
    </Wrapper>
  );
};

export default Question;
