import styled from "styled-components";

export const FooterContainer = styled("div")`
  padding: 1em 5em;
  color: #00000;
  background: #d9d9d9;
  .logo {
    margin: 3em;
    img {
      width: 70%;
    }
  }
  h4 {
    color: #000000;
    font-size: 1.3em;
    font-family: Helvetica-Now-Display-re;
    margin-bottom: 1em;
    text-transform: uppercase;
  }
  .socialLinks {
    display: flex;
    a {
      margin-right: 1em;
      img {
        width: 40px;
      }
    }
  }
  .homeLinks {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    a {
      font-size: 1.3em;
      color: #000000;
      margin-bottom: 1em;
    }
  }
  p {
    font-size: 1.2em;
    color: #000000;
    text-align: center;
    margin-bottom: 2em;
    font-family: Helvetica-Now-Display-lg;
  }
  @media (max-width: 992px) {
    & {
      padding: 1em;
      text-align: center;
      .socialLinks {
        display: flex;
        justify-content: center;
        margin-bottom: 2em;
        a {
          margin-right: 1em;
          margin-bottom: 2em;
          img {
            width: 25px;
          }
        }
      }
      .homeLinks {
        a {
          font-size: 0.9em;
        }
      }
      .logo {
        margin: 3em;
        img {
          width: 50%;
        }
      }
    }
  }
  @media (max-width: 442px) {
    & {
      p {
        font-size: 1em;
        margin-bottom: 1em;
      }
    }
  }
`;

export const Line = styled("div")`
  height: 1px;
  background: #ee1d25;
  margin: 2em 0;
`;
