import React from "react";
import { Border, PlaySection, SocialLinks, Wrapper } from "./index.style";
import { PlayButton } from "../../Home/index.style";
import { useAuth } from "../../../context/AuthContext";

const ThankYou = () => {
  const { score } = useAuth();
  return (
    <Wrapper>
      <PlaySection>
        <div className="content">
          <p>
            Results will be announced <strong>1st week of every month</strong>{" "}
            <br /> on our Social Media Handles
          </p>
          <Border></Border>
          <p>For updates follow our Pages:</p>
          <SocialLinks>
            <a
              href="https://www.facebook.com/profile.php?id=61561910191957"
              target="_blank"
            >
              <img src="/images/f.png" alt="" />
            </a>
            <a
              href="https://www.instagram.com/nuziveeduseedslimited/"
              target="_blank"
            >
              <img src="/images/ista.png" alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/nuziveedu-seeds-limited/"
              target="_blank"
            >
              <img src="/images/lin.png" alt="" />
            </a>
          </SocialLinks>
          <SocialLinks className="downloadBtns">
            <PlayButton className="home-btn" to="/">
              Homepage
            </PlayButton>
            <PlayButton className="home-btn" to="/#certificate">
              Download <br /> Certificate
            </PlayButton>
          </SocialLinks>
        </div>
      </PlaySection>
    </Wrapper>
  );
};

export default ThankYou;
