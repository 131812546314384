import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 2em 7em;
  background-image: linear-gradient(
    0deg,
    transparent,
    transparent,
    transparent,
    transparent,
    #00000020
  );
  h1 {
    color: #000;
    text-align: center;
    // font-family: "Helvetica Now Display";
    font-size: 68px;
  }
`;
export const Content = styled("div")`
  height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 16px;
    width: 16px;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid #00000050;
    border-radius: 10px;
    width: 500px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #ee1d25;
    border-radius: 10px;
  }
`;
export const NewsCard = styled("div")`
  padding: 1em;
  margin-bottom: 1em;
`;
export const NewsAbout = styled("div")`
  padding: 0 4em;
  h4 {
    color: #000;
    font-size: 38px;
    line-height: 1.2;
  }
  p {
    color: #000;
    font-size: 20px;
  }
  a {
    color: #ee1d25;
    font-family: Helvetica-Now-Display-bd;
    font-size: 20px;
  }
  span {
    color: #767676;
  }
`;
export const VideoImg = styled("div")`
  //   width: 597px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 349px;
  overflow: hidden;
  border-radius: 30px;
  background: #d2d2d2;
  margin-bottom: 1em;
  img {
    width: 100%;
  }
`;
