import React from "react";
import { ContactForm, TopSection, Wrapper } from "./index.style";
import { Button, Col, Form, Input, Row, message } from "antd";
import { socialLinks } from "../../assets/data";
import { db } from "../../index.js";
import { Timestamp, doc, setDoc } from "firebase/firestore/lite";
const { TextArea } = Input;

const Contact = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    await setDoc(doc(db, "contact-us", values.email), {
      ...values,
      timeStamp: Timestamp.fromDate(new Date()),
    });

    form.resetFields();
    messageApi.open({
      type: "success",
      content: "Your message send successfully ",
    });
  };
  return (
    <Wrapper>
      {contextHolder}
      <TopSection>
        <img src="/images/bg-contact.jpg" alt="" />
        <h1>
          CONTACT <br /> US
        </h1>
      </TopSection>
      <ContactForm>
        <Row justify="center">
          <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }}>
            <Form form={form} onFinish={onFinish}>
              <h3>Send Us a Message</h3>
              <Form.Item name="name" rules={[{ required: true }]}>
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item name="email" rules={[{ required: true }]}>
                <Input placeholder="Email Address" type="email" />
              </Form.Item>
              <Form.Item name="message" rules={[{ required: true }]}>
                <TextArea
                  autoSize={{
                    minRows: 10,
                    maxRows: 5,
                  }}
                  placeholder="Message"
                />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">Submit</Button>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
            <h3>Address</h3>
            <p>
              Survey No-69, Kandlakoya, Medchal Mandal, Gundlaponchampally,
              Village, Hyderabad, Telangana 501401
            </p>
            <p>
              <hr />
            </p>
            <p>
              Survey No-69, Kandlakoya, Medchal Mandal, Gundlaponchampally,
              Village, Hyderabad, Telangana 501401
            </p>
            <p className="line">
              <hr />
            </p>
            <h3 style={{ marginTop: "1em" }}>Follow Us</h3>
            <div className="socialLinks">
              {socialLinks.map((item, i) => (
                <a key={i} href={item.address}>
                  {item.icon}
                </a>
              ))}
            </div>
            <div className="contactNumber">
              <img src="./images/name.svg" alt="" />
              <a href="tel:1800 572 3630">
                <strong>1800 572 3630</strong>
              </a>
            </div>
          </Col>
        </Row>
      </ContactForm>
    </Wrapper>
  );
};

export default Contact;
