import React, { useState } from "react";
import { CustomForm, Wrapper } from "./index.style";
import { PlaySection } from "../instructions/index.style";
import {
  FormContainer,
  ModalBody,
  ModalHeader,
  SubmitBtn,
} from "../../../common/index.style";
import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    address: "${label} is not a valid address!",
    age: "${label} is not a valid Age!",
    website: "${label} is not a valid website!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const SignUpForm = () => {
  const { formData, setFormData } = useAuth();
  // const { inputValue, setInputValue } = useState("");
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setFormData([...formData, values]);
    // setInputValue(inputValue);
    // form.resetFields();
    navigate("/instructions");
  };

  // const handleChangeNumber = (e) => {
  //   const inputVal = e.target.value;

  //   // Check if the input is a valid number using a regular expression
  //   if (/^[0-9]*$/.test(inputVal)) {
  //     setNumValue(inputVal);
  //   }
  // };

  const navigate = useNavigate();
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  console.log("Form Data ...", formData);
  return (
    <Wrapper>
      <PlaySection>
        <FormContainer>
          <ModalHeader>
            <h2>SIGN UP</h2>
          </ModalHeader>
          <ModalBody>
            <h3>Fill up your information</h3>
            <CustomForm
              form={form}
              layout="vertical"
              name="nest-messages"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Row gutter={26} justify="center">
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item name="name" rules={[{ required: true }]}>
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item
                    name="phone"
                    rules={[{ type: "number", required: true }]}
                  >
                    <InputNumber
                      // value={numValue}
                      // onChange={handleChangeNumber}
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item name="district" rules={[{ required: true }]}>
                    <Input placeholder="District" />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Form.Item name="state" rules={[{ required: true }]}>
                    <Select
                      onChange={handleChange}
                      placeholder="State"
                      options={[
                        {
                          value: "Punjab",
                          label: "Punjab",
                        },
                        {
                          value: "Maharashtra",
                          label: "Maharashtra",
                        },
                        {
                          value: "Bihar",
                          label: "Bihar",
                        },
                        {
                          value: "Arunachal Pradesh",
                          label: "Arunachal Pradesh",
                        },
                        {
                          value: "Assam",
                          label: "Assam",
                        },
                        {
                          value: "Goa",
                          label: "Goa",
                        },
                        {
                          value: "Manipur",
                          label: "Manipur",
                        },
                        {
                          value: "Gujarat",
                          label: "Gujarat",
                        },
                        {
                          value: "Karnataka",
                          label: "Karnataka",
                        },
                        {
                          value: "Kerala",
                          label: "Kerala",
                        },
                        {
                          value: "Andhra Pradesh",
                          label: "Andhra Pradesh",
                        },
                        {
                          value: "Haryana",
                          label: "Haryana",
                        },
                        {
                          value: "Himachal Pradesh",
                          label: "Himachal Pradesh",
                        },
                        {
                          value: "Chhattisgarh",
                          label: "Chhattisgarh",
                        },
                        {
                          value: "Rajasthan",
                          label: "Rajasthan",
                        },
                        {
                          value: "Madhya Pradesh",
                          label: "Madhya Pradesh",
                        },
                        {
                          value: "Mizoram",
                          label: "Mizoram",
                        },
                        {
                          value: "Tamil Nadu",
                          label: "Tamil Nadu",
                        },
                        {
                          value: "West Bengal",
                          label: "West Bengal",
                        },
                        {
                          value: "Uttar Pradesh",
                          label: "Uttar Pradesh",
                        },
                        {
                          value: "Nagaland",
                          label: "Nagaland",
                        },
                        {
                          value: "Odisha",
                          label: "Odisha",
                        },
                        {
                          value: "Jharkhand",
                          label: "Jharkhand",
                        },
                        {
                          value: "Sikkim",
                          label: "Sikkim",
                        },
                        {
                          value: "Tripura",
                          label: "Tripura",
                        },
                        {
                          value: "Telangana",
                          label: "Telangana",
                        },
                        {
                          value: "Delhi",
                          label: "Delhi",
                        },
                        {
                          value: "Meghalaya",
                          label: "Meghalaya",
                        },
                        {
                          value: "Uttarakhand",
                          label: "Uttarakhand",
                        },
                        {
                          value: "Jammu & Kashmir",
                          label: "Jammu & Kashmir",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <SubmitBtn
                    htmlType="submit"
                    // onClick={() => navigate("/instructions")}
                  >
                    Submit
                  </SubmitBtn>
                </Col>
              </Row>
            </CustomForm>
          </ModalBody>
        </FormContainer>
      </PlaySection>
    </Wrapper>
  );
};

export default SignUpForm;
