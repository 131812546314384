import React from "react";
import { Numbers, PlaySection, Wrapper } from "./index.style";
import { NextBtn } from "../../../common/index.style";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { db } from "../../..";
import { Timestamp, doc, setDoc } from "firebase/firestore/lite";

const Result = () => {
  const { score, formData } = useAuth();
  const navigate = useNavigate();

  const handleData = async () => {
    const data = formData[0];

    await setDoc(doc(db, "contest", data.name), {
      data,
      score,
      timeStamp: Timestamp.fromDate(new Date()),
    });
  };

  return (
    <Wrapper>
      <PlaySection>
        <div>
          <Numbers>
            {score}
            {score === 0 ? "0" : ""}/100
          </Numbers>
          <NextBtn
            onClick={() => {
              handleData();
              navigate("/thankyou");
            }}
            style={{ margin: "0 auto 2em" }}
          >
            Next <FiChevronRight />
          </NextBtn>
        </div>
      </PlaySection>
    </Wrapper>
  );
};

export default Result;
