import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled("div")``;
export const PlaySection = styled("div")`
  padding: 3em 7em;
  background-image: url(./images/new-play.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: end;
  text-align: center;
  p {
    color: #ffffff;
    max-width: 800px;
    font-size: 1.5em;
  }
  @media (max-width: 1440px) {
    // height: 671px;
    & {
      padding: 3em 2em 0.5em;
    }
  }
  @media (max-width: 1360px) {
    height: 800px;
    & {
      padding: 3em 2em 0.5em;
    }
  }
  @media (max-width: 992px) {
    & {
      height: 100vh;
      padding: 3em 4em 0.5em;
      background-image: url(./images/play-m.jpg);
      align-items: center;
      p {
        font-size: 1em;
        margin-top: 3em;
      }
    }
  }
`;
export const PlayButton = styled(Link)`
  width: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #fff;
  color: #ee1d25;
  font-size: 50px;
  margin: 1em auto;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  font-family: Helvetica-Now-Display-it-ebd;
  &:hover {
    background: #fff;
    color: #ee1d25;
  }
  @media (max-width: 992px) {
    & {
      width: 136px;
      height: 47px;
      border-radius: 7px;
      font-size: 22px;
    }
  }
`;
