import styled from "styled-components";

export const AboutContainer = styled("div")`
  padding: 2em 0em 6em 5em;
  background-image: linear-gradient(
    0deg,
    #00000020,
    transparent,
    transparent,
    transparent,
    transparent
  );
  h1 {
    font-size: 4em;
    font-family: Helvetica-Now-Display-it-ebd;
    line-height: 1.3;
  }
  p {
    font-family: Helvetica-Now-Display-bd, sans-serif;
    font-size: 2.2em !important;
    line-height: 1.3;
  }
  @media (max-width: 1440px) {
    & {
      h1 {
        font-size: 3em;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      padding: 2em 0em 1em 1em;
      h1 {
        font-size: 2.8em;
        br {
          display: none;
        }
      }
    }
  }
`;
export const RoadMap = styled("div")`
  display: flex;
  flex-direction: row;
  //   width: 1000px;
  // overflow-x: auto;
  padding-bottom: 2em;
  .swiper {
    padding-bottom: 4em;
  }
  .swiper-scrollbar {
    overflow-x: hidden;
    width: 70%;
    height: 7px;
    left: 20% !important;
    bottom: 20px;
    background-color: transparent;
    border: 1px solid #00000050;
    .swiper-scrollbar-drag {
      width: 31% !important;
      background: #ee1d25;
      border-radius: 10px;
    }
  }
  .swiper-button-next {
    top: unset !important;
    bottom: 2px !important;
    right: 5%;
    &::after {
      color: #ee1d25 !important;
      font-weight: bolder;
      font-size: 2em;
    }
  }
  .swiper-button-prev {
    top: unset !important;
    bottom: 2px !important;
    left: 15%;
    &::after {
      color: #ee1d25 !important;
      font-weight: bolder;
      font-size: 2em;
    }
  }
  .slick-slider {
    width: 90%;
  }
  .swiper-wrapper {
    .swiper-slide {
      .items {
        text-align: center;
        width: 200px !important;
        display: inline-flex;
        flex-direction: column;
        .heading {
          margin-top: 3em;
          h5 {
            height: 37px;
            line-height: 1.2;
          }
        }
        p {
          height: 55px;
        }
        .dot {
          width: 20px;
          height: 20px;
          margin: 2.55em auto 0em;
          background: #000000;
          border-radius: 50%;
          position: relative;
          //   z-index: 11;
          &::before {
            content: "";
            background: #ee1d25;
            // z-index: -1;
            width: 360px;
            display: inline-block;
            height: 4px;
            position: absolute;
            top: 8px;
            left: 20px;
            @media (max-width: 1741px) {
              & {
                width: 320px;
              }
            }
            @media (max-width: 1661px) {
              & {
                width: 310px;
              }
            }
            @media (max-width: 1561px) {
              & {
                width: 290px;
              }
            }
            @media (max-width: 1441px) {
              & {
                width: 260px;
              }
            }
            @media (max-width: 1370px) {
              & {
                width: 250px;
              }
            }
            @media (max-width: 1320px) {
              & {
                width: 240px;
              }
            }
          }
        }
      }
      &:nth-child(even) {
        .items {
          flex-direction: column-reverse !important;
          .heading {
            margin-top: 0em;
            h5 {
              height: auto;
            }
          }
          p {
            height: 125px;
            margin-bottom: 3em;
          }
          img {
            margin-bottom: 1.4em;
          }
          .dot {
            margin-top: -1.25em;
            margin-bottom: 2em;
          }
        }
      }
      &:last-child {
        .dot {
          &::before {
            display: none;
          }
        }
      }
    }
  }
  h3 {
    font-family: Helvetica-Now-Display-it-ebd, sans-serif;
    font-size: 2.8em;
  }
  h5 {
    font-family: Helvetica-Now-Display-bd, sans-serif;
    font-size: 1.1em !important;
  }
  p {
    font-size: 1.1em !important;
    font-family: Helvetica-Now-Display-re, sans-serif;
    // height: 125px;
    overflow: hidden;
    width: 201px;
    line-height: 1.2;
  }
  .rightScrollBtn {
    position: absolute;
    bottom: -15px;
    right: 0;
  }
  .leftScrollBtn {
    position: absolute;
    bottom: -15px;
    left: 0;
  }
  @media (max-width: 992px) {
    & {
      .swiper {
        padding-bottom: 3em;
      }
      .swiper-wrapper {
        .swiper-slide {
          .items {
            width: 170px !important;
            p {
              width: 100%;
              height: 76px;
            }
            img {
              width: 49%;
            }
            h3 {
              font-size: 1.8em;
            }
            .heading {
              margin-top: 0em;
              h5 {
                height: 38px;
                font-size: 1em !important;
              }
            }
            .dot {
              width: 10px;
              height: 10px;
              margin: 0.6em auto 0em;
              &::before {
                left: 10px;
                top: 2px;
                height: 2px;
                width: 230px;
              }
            }
          }
          &:nth-child(even) {
            .items {
              flex-direction: column-reverse !important;
              .heading {
                margin-top: 0em;
                h5 {
                  height: auto;
                  margin-bottom: 1em;
                }
              }
              p {
                height: 125px;
                margin-bottom: 3em;
              }
              img {
                margin-bottom: 1.4em;
              }
              .dot {
                margin-top: -0.5em;
                margin-bottom: 1em;
              }
            }
          }
          // &:last-child {
          //   .dot {
          //     &::before {
          //       display: block;
          //     }
          //   }
          // }
        }
      }
      .swiper-scrollbar {
        width: 80%;
        left: 9% !important;
        .swiper-scrollbar-drag {
          width: 33.9% !important;
        }
      }
      .swiper-button-next {
        right: 2%;
      }
      .swiper-button-prev {
        left: 0%;
      }
    }
  }
  @media (max-width: 640px) {
    & {
      .swiper-scrollbar {
        .swiper-scrollbar-drag {
          width: 15.9% !important;
        }
      }
    }
  }
  @media (max-width: 420px) {
    & {
      .swiper-wrapper {
        .swiper-slide {
          .items {
            .dot {
              &::before {
                width: 215px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 400px) {
    & {
      .swiper-wrapper {
        .swiper-slide {
          margin-right: 47px !important;
          .items {
            .dot {
              &::before {
                top: 3.5px;
                width: 200px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 380px) {
    & {
      .swiper-wrapper {
        .swiper-slide {
          .items {
            .dot {
              &::before {
                top: 3px;
                width: 195px;
              }
            }
          }
        }
      }
    }
  }
`;
export const AboutText = styled("div")`
  padding: 2em;
  p {
    max-width: 400px;
  }
  @media (max-width: 992px) {
    text-align: center;
    & {
      p {
        width: 100%;
        font-size: 1.5em !important;
      }
    }
  }
  @media (max-width: 400px) {
    & {
      padding: 2em 1em !important;
      h1 {
        font-size: 2.4em !important;
      }
    }
  }
`;
