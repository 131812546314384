import React from "react";
import Header from "../header";
import { Outlet } from "react-router-dom";
import MainFooter from "../footer";

const MainLayout = () => {
  return (
    <>
      <Header />
      <div>
        <Outlet />
      </div>
      <MainFooter />
    </>
  );
};

export default MainLayout;
