import React from "react";
import { AboutContainer, AboutText, RoadMap } from "./index.style";
import { Col, Row } from "antd";
import { yearsData } from "../../assets/data";
import Slider from "react-slick";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const AboutSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  return (
    <AboutContainer>
      <Row align="middle">
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <AboutText>
            <h1>
              OVER 50 <br />
              YEARS OF <br />
              INNOVATION
            </h1>
            <p>
              Every mark is a testament to our remarkable journey since 1973
            </p>
          </AboutText>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 16 }}>
          <RoadMap>
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                300: {
                  slidesPerView: 2,
                },
                400: {
                  slidesPerView: 2,
                },

                992: {
                  slidesPerView: 3.5,
                },
              }}
              spaceBetween={50}
              // slidesPerView={3.5}
              navigation
              // pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              {yearsData.map((item, i) => (
                <SwiperSlide>
                  <div className="items">
                    <div
                      className="heading"
                      style={item.year == "2013" ? { marginTop: "0.35em" } : {}}
                    >
                      <h5>{item.name_title}</h5>
                      <p>{item.disc}</p>
                    </div>

                    <div className="dot"></div>
                    <h3>{item.year}</h3>
                    <div>
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* <span className="rightScrollBtn">
              <img src="./images/right-arrow.svg" alt="" />
            </span>
            <span className="leftScrollBtn">
              <img src="./images/left.png" alt="" />
            </span> */}
          </RoadMap>
        </Col>
      </Row>
    </AboutContainer>
  );
};

export default AboutSection;
