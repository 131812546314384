import "./App.css";
import "antd/dist/antd.min.css";
import AllRoutes from "./routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthProvider } from "./context/AuthContext";

// test deployment
function App() {
  return (
    <div className="App">
      <AuthProvider>
        <AllRoutes />
      </AuthProvider>
    </div>
  );
}

export default App;
