import styled from "styled-components";

export const Wrapper = styled("div")``;
export const AboutText = styled("div")`
  padding: 0.5em 1em;
  p {
    color: #000000;
    font-size: 20px;
  }
  ol {
    text-align: left;
    li {
      text-align: left;
      line-height: 2;
      color: #000000;
      font-size: 20px;
      strong {
        color: red;
      }
      a {
        color: #000000;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      padding: 1em;
      ol {
        li {
          font-size: 14px;
          line-height: 1.5;
        }
      }
    }
  }
`;
export const PlaySection = styled("div")`
  padding: 3em 7em;
  background-image: url(/images/quiz_banner.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 90.9vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  //   p {
  //     color: #ffffff;
  //     max-width: 800px;
  //     font-size: 1.5em;
  //   }

  @media (max-width: 1360px) {
    & {
      height: 800px;
    }
  }
  @media (max-width: 992px) {
    & {
      background-image: url(/images/play-3.jpg);
      padding: 3em 2em;
      height: 100vh;
    }
  }
`;
