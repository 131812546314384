import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  background: #ffffff;
  position: relative;
  padding: 0 2em;
  z-index: 1;
  .homeLogo {
    border-radius: 10px;
    img {
      max-width: 100%;
    }
  }

  @media (max-width: 992px) {
    & {
      padding: 0 1em;
      height: 40px;
      justify-content: space-between;
      .homeLogo {
        img {
          max-width: 60%;
        }
      }
    }
  }
`;
export const Nav = styled("nav")`
  display: flex;
  @media (max-width: 992px) {
    & {
      display: none;
    }
  }
`;
export const ToggleBtn = styled("span")`
  display: none;
  height: 30px;
  width: 15px;
  img {
    height: 100%;
  }
  @media (max-width: 992px) {
    & {
      height: auto;
      display: block;
    }
  }
`;
export const MobileNav = styled("nav")`
  display: none;
  background: #ee1d25;
  position: absolute;
  left: 0;
  right: 0;
  top: 39px;
  padding: 2em 1em 4em;
  transition: 500ms;
  @media (max-width: 992px) {
    & {
      display: flex;
      flex-direction: column;
      a {
        font-size: 14px;
      }
    }
  }
`;
export const LinkItem = styled(Link)`
  color: #000000;
  margin: 0.6em 1em;
  font-size: 18px;
  font-style: normal;
  letter-spacing: 1px;
  font-weight: 700;
  line-height: normal;
  &:hover {
    color: #000000;
  }
`;
