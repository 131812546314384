import React, { useState, createContext, useContext } from "react";
export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [formData, setFormData] = useState([]);
  const [certificateFormData, setCertificateFormData] = useState({});
  const [score, setScore] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [disabledContent, setDisabledContent] = useState(true);

  return (
    <AuthContext.Provider
      value={{
        formData,
        setFormData,
        certificateFormData,
        setCertificateFormData,
        score,
        setScore,
        answers,
        setAnswers,
        disabledContent,
        setDisabledContent,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
