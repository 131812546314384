import styled from "styled-components";

export const CardContainer = styled("div")`
  max-width: 980px;
  height: auto;
  padding: 3em 1em;
  margin: 6em auto;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 992px) {
    & {
      width: 310px;
      height: 155px;
      padding: 1.5em 1em 0.5em;
      border-radius: 15px;
      p {
        line-height: 12px !important;
      }
    }
  }
`;
export const CardFooter = styled("div")`
  display: flex;
  align-items: center;
  h6 {
    font-size: 1.9em !important;
    line-height: 1;
    margin-bottom: 0;
  }
  span {
    color: #ec0000;
    font-family: Helvetica-Now-Display-it;
    font-size: 22px;
    font-style: italic;
  }
  img {
    margin-right: 1em;
  }
  @media (max-width: 992px) {
    & {
      img {
        margin-right: 0.5em;
        width: 13px;
        height: 13px;
      }
      h6 {
        font-size: 12px !important;
        line-height: 1 !important;
        margin-bottom: 0;
      }
      span {
        font-size: 10px;
      }
    }
  }
`;
export const UserCard = styled("div")`
  display: flex;
  align-items: center;
  border-left: 1px solid #e4e4e4;
  padding: 5em 5em 2em;
  position: relative;
  h5 {
    color: #000;
    font-family: Helvetica-Now-Display-it-ebd;
    font-size: 2.2em !important;
    margin-bottom: 0em;
  }
  p {
    color: #000;
    font-family: Helvetica-Now-Display-bd, sans-serif;
    font-size: 1.6em !important;
    font-style: normal;
    margin-bottom: 0em;
    font-weight: 700;
  }
  @media (max-width: 1640px) {
    & {
      padding: 4em 4em 2em;
      p {
        font-size: 1.3em !important;
      }
    }
  }
  @media (max-width: 1470px) {
    & {
      padding: 4em 3em 2em;
    }
  }
  @media (max-width: 1440px) {
    & {
      p {
        font-size: 1.2em !important;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      border-bottom: 1px solid #e4e4e4;
      padding: 2em 1.5em 1em;
      p {
        font-size: 0.9em !important;
      }
      h5 {
        font-size: 1.2em !important;
        margin-bottom: 0em;
      }
    }
  }
  @media (max-width: 400px) {
    & {
      padding: 2em 1.3em 1em;
      p {
        font-size: 0.8em !important;
      }
    }
  }
  @media (max-width: 380px) {
    & {
      padding: 2em 1em 1em;
      p {
        font-size: 0.8em !important;
      }
    }
  }
`;
export const CountNumber = styled("div")`
  font-size: 87px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #ffff;
  font-family: Helvetica-Now-Display-it-ebd;
  text-shadow: 0px 0px 1px #000000;
  position: absolute;
  top: 10px;
  left: 5%;
  z-index: -1;
  @media (max-width: 1470px) {
    & {
      top: 8px;
      left: 4%;
      font-size: 77px;
    }
  }
  @media (max-width: 1440px) {
    & {
      top: 5px;
      left: 3%;
      font-size: 70px;
    }
  }
  @media (max-width: 992px) {
    & {
      top: 0px;
      font-size: 42px;
    }
  }
  @media (max-width: 440px) {
    & {
      top: 4px;
      font-size: 34px;
    }
  }
`;
export const Avatar = styled("div")`
  width: 82px;
  height: 82px;
  padding: 1em;
  background: #ee1d25;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1em;
  img {
    width: 100%;
  }
  @media (max-width: 1440px) {
    & {
      width: 70px;
      height: 70px;
    }
  }
  @media (max-width: 992px) {
    & {
      padding: 0.4em;
      width: 36px;
      height: 36px;
    }
  }
`;
export const CardDisc = styled("div")`
  text-align: left;
  p {
    font-size: 20px;
  }
  @media (max-width: 992px) {
    & {
      p {
        font-size: 11px !important;
      }
    }
  }
`;
export const Employee = styled("div")`
  width: 230px;
  height: 230px;
  margin: 0em auto;
  overflow: hidden;
  border-radius: 50%;
  background: #d9d9d9;
  @media (max-width: 992px) {
    & {
      width: 70px;
      height: 70px;
    }
  }
`;
export const FarmerCardContainer = styled("div")`
  max-width: 383px;
  height: 553px;
  display: flex;
  justify-content: center;
  align-items: bottom;
  flex-direction: column;
  position: relative;
  padding: 2em 2em 0em;
  margin: 4em auto;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  p {
    font-size: 18px !important;
    margin-top: 2em;
    text-align: center;
    height: 300px;
  }
  @media (max-width: 1440px) {
    & {
      max-width: 300px;
      height: 480px;
      p {
        font-size: 14px !important;
        height: 235px;
        margin-top: 2em;
      }
    }
  }
  @media (max-width: 992px) {
    & {
      max-width: 323px;
      height: 451px;
      p {
        font-size: 14px !important;
        margin-top: 1.5em;
        height: 260px;
      }
    }
  }
  @media (max-width: 400px) {
    & {
      max-width: 276px;

      p {
        font-size: 15px !important;
        margin-top: 1.5em;
      }
    }
  }
`;
export const FarmerCardHeader = styled("div")`
  position: absolute;
  top: -10%;
  right: 50%;
  transform: translateX(50%);
`;
export const FarmerAvatar = styled("div")`
  width: 120px;
  height: 120px;
  margin: 0em auto;
  border-radius: 50%;
  background: #d9d9d9;
  @media (max-width: 992px) {
    & {
      width: 90px;
      height: 90px;
    }
  }
`;
export const FarmerCardFooter = styled("div")`
  text-align: center;
  .dots {
    text-align: center;
    position: relative;
    margin: 1em auto;
    div {
      width: 30px;
      background: #ffffff;
      margin: 1em auto;
      position: relative;
      z-index: 2;
      img {
        width: 25px;
        margin: 1em auto;
      }
      h6 {
        font-size: 1.2em;
      }
    }
    &::after,
    &::before {
      content: "";
      display: inline-block;
      height: 3px;
      width: 40%;
      background-color: red;
      position: absolute;
      z-index: 1;
      top: 8px;
      left: 0;
    }
    &::after {
      left: unset;
      right: 0;
    }
  }
  h6 {
    font-size: 25px;
    margin-bottom: 0;
  }
  span {
    font-size: 17px;
    color: #ee1d25;
    text-align: center;
    font-style: italic;
  }
  @media (max-width: 992px) {
    & {
      h6 {
        font-size: 14px;
      }
      span {
        font-size: 13px;
      }
    }
  }
`;
