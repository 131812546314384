import React from "react";
import { CardContainer, CardDisc, CardFooter, Employee } from "./index.style";
import { Col, Row } from "antd";

const EmployeeCard = ({ data }) => {
  return (
    <CardContainer>
      <Row align="middle">
        <Col span={8}>
          <Employee>
            <img src={data.avatar} alt="" />
          </Employee>
        </Col>
        <Col span={16}>
          <CardDisc>
            <p>{data.disc}</p>

            <CardFooter>
              <img src="./images/marks.svg" alt="" />
              <div>
                <h6>{data.userName}</h6>
                <span>{data.bio}</span>
              </div>
            </CardFooter>
          </CardDisc>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default EmployeeCard;
