import React from "react";
import { Content, NewsAbout, NewsCard, VideoImg, Wrapper } from "./index.style";
import { TopHeading } from "../gallery/index.style";
import { Col, Row } from "antd";
import { BsChevronRight } from "react-icons/bs";

const News = () => {
  const newsData = [
    {
      image: "/images/image-2.png",
    },
    {
      image: "/images/image-2.png",
    },
    {
      image: "/images/image-2.png",
    },
    {
      image: "/images/image-2.png",
    },
    {
      image: "/images/image-2.png",
    },
    {
      image: "/images/image-2.png",
    },
    {
      image: "/images/image-2.png",
    },
  ];
  return (
    <Wrapper>
      <TopHeading>
        <h1>NEWS</h1>
      </TopHeading>
      <Content>
        {newsData.map((item, i) => (
          <NewsCard>
            <Row gutter={26}>
              <Col span={10}>
                <VideoImg>
                  <img src="/images/image-2.png" alt="" />
                </VideoImg>
              </Col>
              <Col span={14}>
                <NewsAbout>
                  <span>25 Dec, 2024</span>
                  <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod.
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <a href="">
                    Read More <BsChevronRight />
                  </a>
                </NewsAbout>
              </Col>
            </Row>
          </NewsCard>
        ))}
      </Content>
    </Wrapper>
  );
};

export default News;
