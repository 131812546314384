import React from "react";
import { Avatar, CountNumber, UserCard } from "./index.style";
// import { Col, Row } from "antd";

const Users = ({ data }) => {
  return (
    <UserCard>
      <CountNumber>{data.id}</CountNumber>
      <Avatar>
        <img src={data.image} alt="" />
      </Avatar>
      <div>
        <h5>{data.name_title}</h5>
        <p>{data.disc}</p>
      </div>
    </UserCard>
  );
};

export default Users;
